import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UiSvg from '../UiSvg';
import styles from './style.m.less';
function Spinner(_a) {
    var { isSpinning, className } = _a, other = __rest(_a, ["isSpinning", "className"]);
    const spinnerClasses = classNames(styles.container, className);
    return isSpinning ? _jsx(UiSvg, Object.assign({ className: spinnerClasses, name: "loading-spinner" }, other)) : _jsx("noscript", {});
}
Spinner.propTypes = {
    isSpinning: PropTypes.bool,
};
Spinner.defaultProps = {
    isSpinning: true,
};
export default Spinner;
