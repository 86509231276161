import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cN from 'classnames';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs as ReactTabs } from 'react-tabs';
import { trackEvent } from '../../services/Tracking';
import style from './style.m.less';
const propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        panel: PropTypes.element,
        disabled: PropTypes.bool,
        trackingLabel: PropTypes.string,
    })),
    classNames: PropTypes.shape({
        tabContainer: PropTypes.string,
        tabList: PropTypes.string,
        tab: PropTypes.string,
        panel: PropTypes.string,
    }),
    onSelect: PropTypes.func,
    selectedIndex: PropTypes.number,
    eventTracking: PropTypes.shape({
        category: PropTypes.string.isRequired,
        action: PropTypes.string,
    }),
};
class Tabs extends Component {
    render() {
        const { tabs, children, classes, classNames = {}, compact, selectedIndex, onSelect, eventTracking, } = this.props;
        return (_jsxs(ReactTabs, { selectedTabClassName: style.selected, disabledTabClassName: style.disabled, className: cN(style.container, classes, classNames.tabContainer, { [style.compact]: compact }), onSelect: (index, last, event) => {
                if (eventTracking) {
                    trackEvent(Object.assign(Object.assign({}, eventTracking), { label: tabs[index].trackingLabel }));
                }
                return onSelect === null || onSelect === void 0 ? void 0 : onSelect(index, last, event);
            }, selectedIndex: selectedIndex !== undefined ? parseInt(String(selectedIndex)) : undefined, children: [_jsxs(TabList, { className: cN(style.tabList, classNames.tabList), children: [map(tabs, (tab, i) => (_jsx(Tab, { className: cN(style.tab, classNames.tab), disabled: tab.disabled, children: tab.header }, i))), children ? _jsx("div", { className: style.tabOptions, children: children }) : null] }), map(tabs, (tab, i) => (_jsx(TabPanel, { className: cN(style.tabPanel, classNames.panel), children: tab.panel }, i)))] }));
    }
}
Tabs.propTypes = propTypes;
export default Tabs;
