import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import PropTypes from 'prop-types';
import style from './style.m.less';
import AriaLabel from './../../AriaHelper/AriaLabel';
export default function PopupAlertStatus({ status, children }) {
    if (!status) {
        return _jsx(_Fragment, { children: children });
    }
    let c, label;
    if (status === 'success') {
        c = style.success;
        label = 'Success: ';
    }
    else if (status === 'error') {
        c = style.error;
        label = 'Error: ';
    }
    else {
        c = style.info;
        label = 'Info: ';
    }
    return (_jsxs("div", { className: c, children: [_jsx(AriaLabel, { children: label }), children] }));
}
PopupAlertStatus.propTypes = {
    children: PropTypes.node.isRequired,
    status: PropTypes.oneOf(['info', 'success', 'error']),
};
