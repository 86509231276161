import { curry, compose, join, slice, concat } from 'lodash/fp';
// :: String -> [String] -> [String]
const joinLastTwo = curry((separator, ss) => {
    if (ss.length < 2)
        return ss;
    return concat(slice(0, ss.length - 2, ss), join(separator, slice(ss.length - 2, ss.length, ss)));
});
// :: String -> [String] -> [String]
const crop = curry((limit, ss) => {
    const restCount = ss.length - limit;
    if (restCount <= 0)
        return ss;
    const trunk = slice(0, limit, ss);
    return concat(slice(0, limit - 1, trunk), `${restCount + 1} others`);
});
// :: Number -> [String] -> String
/**
 * @type {(limit: number, words: string[]) => string}
 */
export const friendlyList = curry((limit, words) => compose(join(', '), joinLastTwo(' and '), crop(limit))(words));
