import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconX } from 'featherico';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { animated, useSpring } from 'react-spring';
import singleton from '../../../common/models/popup-alert/singleton';
import history from '../../../routing/history';
import { AriaAnnouncer } from '../AriaHelper';
import { InputButtonIcon } from '../Input/InputButton';
import PopupAlertStatus from './PopupAlertStatus';
import styles from './style.m.less';
const PopupAlert = ({ model, onDismiss }) => {
    const style = useSpring({ opacity: model.isOpen ? 1 : 0, transform: `translateY(${model.isOpen ? 0 : -200}px)` });
    return (_jsxs(animated.div, { className: styles.popup_alert, style: style, children: [_jsx(InputButtonIcon, { className: styles.dismiss, onClick: onDismiss, "aria-hidden": true, children: _jsx(IconX, {}) }), _jsx(PopupAlertStatus, { status: model.status, children: model.message })] }));
};
const PopupAlertContainer = ({ mountNode }) => {
    const [model, setModel] = useState(singleton.getModel());
    useEffect(() => {
        mountNode.classList.add(styles.outerContainer);
        return () => mountNode.classList.remove(styles.outerContainer);
    }, [mountNode]);
    useEffect(() => {
        if (!history) {
            return;
        }
        return history.listen(() => {
            if (!singleton.getModel().surviveNavigation) {
                singleton.dismiss();
            }
        });
    }, []);
    useEffect(() => {
        const handler = (newModel) => {
            setModel(oldModel => {
                if (oldModel.isOpen) {
                    setTimeout(() => {
                        handler(newModel);
                    }, 1000);
                    return Object.assign(Object.assign({}, oldModel), { isOpen: false });
                }
                return newModel;
            });
        };
        singleton.subscribe(handler);
        return () => singleton.unsubscribe(handler);
    }, []);
    return (_jsx(AriaAnnouncer, { hiddenFromView: false, ariaRole: "region", ariaLive: "assertive", isAtomic: true, children: _jsx("div", { className: styles.innerContainer, children: _jsx(PopupAlert, { model: model, onDismiss: () => singleton.dismiss() }) }) }));
};
export default PopupAlertContainer;
