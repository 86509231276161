import { __rest } from "tslib";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import * as React from 'react';
import Modal from '../../components/Modal';
/**
 * Component that manages open/closed state for a modal
 *
 * ```
 * <WithModal
 *     {...modalProps}
 *     onClose={handleClose}
 *     renderModalContent={({openModal, closeModal})=><ModalContent onCancel={closeModal} />}
 * >
 *   { ({closeModal, openModal}) => <ModalTriggerComponent onClick={openModal}/> }
 * </WithModal>
 * ```
 */
export default class WithModal extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            isOpen: false,
        };
        this.actions = {
            openModal: () => {
                this.setState({ isOpen: true });
            },
            closeModal: () => {
                this.setState({ isOpen: false });
                if (this.props.onClose) {
                    this.props.onClose();
                }
            },
        };
    }
    render() {
        const _a = this.props, { renderModalContent, children: render } = _a, props = __rest(_a, ["renderModalContent", "children"]);
        return (_jsxs(_Fragment, { children: [_jsx(Modal, Object.assign({}, props, { isOpen: this.state.isOpen, onClose: this.actions.closeModal, children: renderModalContent(this.actions) })), render(this.actions)] }));
    }
}
