import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './style.m.less';
import PropTypes from 'prop-types';
import classNames from 'classnames';
LoadingBar.propTypes = {
    label: PropTypes.string,
    withLabel: PropTypes.bool,
    className: PropTypes.string,
};
export default function LoadingBar({ label = 'Loading...', withLabel = true, className = undefined }) {
    const containerClasses = classNames(styles.container, className);
    return (_jsxs("div", { className: containerClasses, children: [_jsx("div", { className: styles.bar }), withLabel && _jsx("small", { className: styles.label, children: label })] }));
}
