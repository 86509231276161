import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import classNames from 'classnames';
function UiSvg({ name, className }) {
    const svg = require('../../../common/components/ui-svg-include/svgs/' + name + '.svg');
    const props = {
        dangerouslySetInnerHTML: {
            __html: svg,
        },
        className: classNames('react-ui-svg', className),
    };
    return _jsx("span", Object.assign({}, props));
}
UiSvg.propTypes = {
    name: PropTypes.string.isRequired,
};
export default UiSvg;
